import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/toggle_on.png'
import _imports_1 from '@/assets/images/toggle_off.png'


const _hoisted_1 = {
  key: 0,
  class: "flex flex-wrap justify-end items-center content-center text-textLight dark:text-textDark text-base w-mobile sm:w-median lg:w-large mx-auto"
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  class: "rounded-md bg-white shadow-xs w-56",
  role: "menu",
  "aria-orientation": "vertical",
  "aria-labelledby": "options-menu"
}
const _hoisted_6 = { class: "pt-2 z-30 absolute" }
const _hoisted_7 = ["id"]
const _hoisted_8 = ["onClick", "id"]
const _hoisted_9 = { class: "mx-2" }
const _hoisted_10 = { class: "my-auto" }
const _hoisted_11 = {
  key: 0,
  src: _imports_0,
  width: "35px",
  height: "35px",
  alt: "toggle on",
  class: "w-auto h-4 cursor-pointer object-cover"
}
const _hoisted_12 = {
  key: 1,
  src: _imports_1,
  width: "35px",
  height: "35px",
  alt: "toggle off",
  class: "w-auto h-4 cursor-pointer object-cover"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ps_icon = _resolveComponent("ps-icon")!
  const _component_ps_label = _resolveComponent("ps-label")!
  const _component_ps_dropdown = _resolveComponent("ps-dropdown")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["flex fixed z-50 w-full h-8 lg:h-12 bg-secondary-900", _ctx.topOfPage ? ' ' : 'hidden'])
  }, [
    (_ctx.dataReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ps_dropdown, {
              align: "right",
              h: "h-72",
              class: ""
            }, {
              select: _withCtx(() => [
                _createVNode(_component_ps_label, { class: "rounded-md shadow-sm h-full" }, {
                  default: _withCtx(() => [
                    _createElementVNode("button", {
                      type: "button",
                      class: "inline-flex items-center content-start justify-between w-full h-12 leading-5 font-medium text-base focus:outline-none transition ease-in-out duration-150 btn-focus",
                      id: _ctx.activeLanguage.name,
                      "aria-haspopup": "true",
                      "aria-expanded": "true"
                    }, [
                      _createElementVNode("img", {
                        src: _ctx.getFlag(_ctx.activeLanguage.languageCode),
                        alt: _ctx.activeLanguage.name,
                        width: "35px",
                        height: "35px",
                        class: "me-1 w-auto h-4 cursor-pointer object-cover"
                      }, null, 8, _hoisted_4),
                      _createVNode(_component_ps_icon, {
                        name: "downArrow",
                        class: "text-textLight",
                        w: "24",
                        h: "24"
                      })
                    ], 8, _hoisted_3)
                  ]),
                  _: 1
                })
              ]),
              list: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supportedLanguages, (language) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: language.languageCode,
                        textSize: "text-sm"
                      }, [
                        (language.languageCode == _ctx.activeLanguage.languageCode)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              id: language.name,
                              class: "w-56 py-4 px-2 cursor-pointer flex flex-row items-center"
                            }, [
                              _createVNode(_component_ps_label, { class: "font-medium" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(language.name), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ], 8, _hoisted_7))
                          : (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              onClick: ($event: any) => (_ctx.changeLanguage(language)),
                              id: language.name,
                              class: "font-light w-56 py-4 px-2 hover:bg-primary-000 dark:hover:bg-primaryDark-black cursor-pointer flex flex-row items-center"
                            }, [
                              _createVNode(_component_ps_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(language.name), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ], 8, _hoisted_8))
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_ps_label, {
            onClick: _ctx.toggleDarkMode,
            class: "cursor-pointer flex flex-row"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_ps_label, {
                  class: "text-xs lg:sm",
                  textColor: "text-textLight font-medium"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("dark_mode_or_light_mode")), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.isDarkMode)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                  : (_openBlock(), _createElementBlock("img", _hoisted_12))
              ])
            ]),
            _: 1
          }, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}


// Libs
import {  ref } from 'vue';

import router from "@/router";

// Components
import PsInputWithLeftIcon from '@/components/core/input/PsInputWithLeftIcon.vue';
import PsIcon from '@/components/core/icons/PsIcon.vue';
// Providers
import { useProductProviderState } from '@/store/modules/item/ProductProvider';
import { PsValueProvider } from "@/store/modules/core/PsValueProvider";
import AppInfoParameterHolder from '@/object/holder/AppInfoParameterHolder';
import { usePsAppInfoProviderState } from '@/store/modules/appinfo/AppInfoProvider';



export default {
    name : "SearchForLargeScreenView",
    components: {
        PsInputWithLeftIcon,
        PsIcon,
    },
    props : {
        data : {
            type: String,
            default: '0'
        },  
    },
    emits: ['onSubclick'],
    setup() {
        
        // Inject Provider
        
        const psValueHolder = PsValueProvider.psValueHolder;
    
        psValueHolder.updateExpiryTimeForAction(); // only call from dashboard
        const appInfoProvider = usePsAppInfoProviderState();
        const searchKeyword = ref("");


        const itemProvider = useProductProviderState();


        const loginUserId = psValueHolder.getLoginUserId();


        async function searchClicked() {
            

            itemProvider.paramHolder.searchTerm = searchKeyword.value;
            itemProvider.paramHolder.status = '1';

            searchKeyword.value = '';
            
            const params = itemProvider.paramHolder.getUrlParamsAndQuery();
            params['name'] = 'item-list';
            console.log(params);
            router.push(params);
            
        }


        const appInfoParameterHolder = new AppInfoParameterHolder();
        appInfoParameterHolder.userId = loginUserId;
        appInfoProvider.loadDeleteHistory(appInfoParameterHolder);

        return {
            PsValueProvider,
            itemProvider,
            searchKeyword,
            searchClicked,
            appInfoProvider,
        };
    },
};

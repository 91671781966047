import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full sm:flex sm:mt-0" }
const _hoisted_2 = { class: "me-1 flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ps_icon = _resolveComponent("ps-icon")!
  const _component_ps_input_with_left_icon = _resolveComponent("ps-input-with-left-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ps_input_with_left_icon, {
        theme: "ring-primary-200 dark:ring-primaryDark-grey focus:outline-none focus:ring focus:border-primary-200 lg:rounded-2xl rounded-x bg-primary-000 dark:bg-primaryDark-black text-secondary-500 dark:text-secondaryDark-white",
        height: "h-10",
        onKeyup: _withKeys($setup.searchClicked, ["enter"]),
        value: $setup.searchKeyword,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchKeyword) = $event)),
        placeholder: _ctx.$t('search_for_large_screem__search')
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_ps_icon, {
            textColor: "text-secondary-400 dark:text-secondaryDark-grey",
            name: "search",
            class: "cursor-pointer"
          })
        ]),
        _: 1
      }, 8, ["onKeyup", "value", "placeholder"])
    ])
  ]))
}
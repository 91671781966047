export default class PsConfig {

    static versionNo : String = '1.3';

    ///
    /// API Key to connection with Main Backend
    /// This key must equal with key from backend
    ///
    static apiKey: String = "APIi5dVg7GOhdK7sO81pEvw271XfSuRbDyR";

    ///
    /// Live Mode
    /// ------------
    /// If you want to upload to server, please enable below and comment Development below
    ///
    static fixedDomain: String = "https://lyvano.com/admin"; 
    static domain: String = "https://lyvano.com/admin";  
    //static domain: String = "http://localhost:3000"; 

    

    ///
    /// Development Mode
    /// -----------------
    /// Before upload to server, please comment this 2 lines.
    ///

    ///
    /// Firebase Config
    ///
    // static firebaseConfig = {
    //     apiKey: "AIzaSyCadnaxeRni51peKK9fkJAbIRxu4atU408",
    //     authDomain: "lyvano-9c327.firebaseapp.com",
    //     databaseURL: "https://lyvano-9c327-default-rtdb.europe-west1.firebasedatabase.app",
    //     projectId: "lyvano-9c327",
    //     storageBucket: "lyvano-9c327.appspot.com",
    //     messagingSenderId: "992186381233",
    //     appId: "1:992186381233:web:fcc230cdb92b6a40b8c390",
    //     measurementId: "G-4G25CMJK18"
    // };

    static firebaseConfig = {
        apiKey: "AIzaSyCadnaxeRni51peKK9fkJAbIRxu4atU408",
        authDomain: "lyvano-9c327.firebaseapp.com",
        databaseURL: "https://lyvano-9c327-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "lyvano-9c327",
        storageBucket: "lyvano-9c327.appspot.com",
        messagingSenderId: "992186381233",
        appId: "1:992186381233:web:fcc230cdb92b6a40b8c390",
        measurementId: "G-4G25CMJK18"
    };
    
    
    ///
    /// Base URL * Don't update it *
    ///
    static base: String = PsConfig.domain; 
    static baseUrl: String = PsConfig.domain + '/index.php';
    static fixedPath : String = PsConfig.fixedDomain;
 
    /// 
    /// Debug Mode
    ///
    static isDebugMode : Boolean = true;

    /// Is Demo
    ///
    static is_demo : Boolean = true;

    static item_upload_ui1 : Boolean = false;

    static item_upload_ui2 : Boolean = true;
}
